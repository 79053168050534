import * as React from 'react';

interface StructuredDataProps {
  title: string;
  url: string;
  description?: string;
  children?: any[] | any;
  type?: string;
}


const StructuredData = (props: StructuredDataProps) => {
  const scriptTag = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "${props.title}",
      "author": "Alphapro",
      "url": "${props.url}",
      "description": "${props.description}"
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "email": "info@alphapro.hu",
      "name": "Alphapro",
      "telephone": "06 70 / 907 23 33",
      "url": "https://www.alphapro.hu",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Budapest, Hungary",
        "postalCode": "1139",
        "streetAddress": "Frangepán utca 46."
      }
    }
    </script>
`;

  return <>
    <div dangerouslySetInnerHTML={{ __html: scriptTag }} />
  </>;
};

export default StructuredData;
