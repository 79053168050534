import * as React from "react"
import Layout from "../components/layout"
import { Fade, makeStyles } from "@material-ui/core"
import SendMessageBanner from "../components/send-message-banner/send-message-banner"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"

const useStyles = makeStyles((theme) => ({
  map: {
    border: 0,
    width: "100%",
    height: 250,
    marginBottom: -6
  }
}))

const KapcsolatPage = (props) => {
  const classes = useStyles()

  return <Layout>
    <MetaData title={'Kapcsolat, ha felkeltettük az érdeklődésedet'} url={props.location.href} description={"Felkeltettük az érdeklődésedet? Vedd fel velünk a kapcsolatot! Az üzenet elküldése után 72 órán belül jelentkezni fogunk."}/>
    <StructuredData title={'Kapcsolat, ha felkeltettük az érdeklődésedet'} url={props.location.href} description={"Felkeltettük az érdeklődésedet? Vedd fel velünk a kapcsolatot! Az üzenet elküldése után 72 órán belül jelentkezni fogunk."}/>
    <SendMessageBanner isPaper={true} isConnectionPage={true}/>
    <iframe
      //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.9242574949676!2d19.01987891561652!3d47.49138887917721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc2920726697%3A0x7d44d93e5a2b593!2sBudapest%2C%20Brassai%20S%C3%A1muel%20u.%2016%2C%201126!5e0!3m2!1shu!2shu!4v1621256022765!5m2!1shu!2shu"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2693.709204321955!2d19.077826616416115!3d47.53452417918003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741db9578fa967b%3A0x777052e717bd7e06!2sBudapest%2C%20Frangep%C3%A1n%20u.%2046%2C%201139!5e0!3m2!1shu!2shu!4v1622033941914!5m2!1shu!2shu"
      className={classes.map}
      loading="lazy"
      title={"Címünk"} />
  </Layout>
}

export default KapcsolatPage
